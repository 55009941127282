<template lang="pug">
TheDetail(
	:groups="groups"
	title="Завод"
	title-new="Создать завод"
	@remove="removeFactory"
	@save="saveFactory"
)
ModalWorkers(v-model:show="modal" endpoint="factory" @create="createWorker")
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'

import { useApi } from '../modules/api'
import { useDetail } from '../modules/composition/useDetail'
import { useBasic } from '../modules/composition/inputs/useBasic'
import { useWorkers } from '../modules/composition/useWorkers'
import { useFishes } from '../modules/composition/useFishes'

import TheDetail from '../components/TheDetail'
import ModalWorkers from '../components/modals/ModalWorkers'

export default {
	name: 'PortDetail',
	components: { TheDetail, ModalWorkers },
	setup () {
		const { idDetail, isNew, notFoundDetail, saveDetail, removeDetail } = useDetail()

		const saveFactory = async () => {
			const body = bodyBasic()

			await saveDetail({
				endpoint: '/factory/create',
				body,
				workers,
				createWorkerApi
			})
		}

		const removeFactory = async () => {
			await removeDetail(`/factory/${idDetail}`)
		}

		const createWorkerApi = async ({ id, workerId, postId }) => {
			const { post, data } = useApi('/factory/createFactory2worker')

			await post({
				factory: { id: id || idDetail },
				worker: { id: workerId },
				workerPost: { id: postId }
			})

			return data.value?.id
		}

		const updateWorkerApi = () => {
			return new Promise((resolve, reject) => {
				(async () => {
					try {
						const { get, data, error } = useApi(`/factory/${idDetail}`)

						await get()

						if (error.value) {
							throw new Error('Не удалось обновить список работников')
						}

						const workerList = data.value.listOfWorkers.reduce((acc, worker) => {
							const { id, workerFIO: title, postName: status } = worker
							acc[id] = { id, title, status }
							return acc
						}, {})
						resolve(workerList)
					} catch (error) {
						reject(error)
					}
				})()
			})
		}

		const { workers, setWorker, createWorker, deleteWorker } = useWorkers({
			updateWorkerApi,
			createWorkerApi,
			deleteEndpoint: '/factory/deleteFactory2worker',
			idDetail,
			isNew
		})

		const { basic, setBasic, bodyBasic } = useBasic()
		const { fishes, setFishes } = useFishes()

		const getAction = async () => {
			const { get, data } = useApi(`/factory/${idDetail}/actions`)
			await get()

			return data.value || []
		}

		const groups = reactive({
			main: {
				title: 'Общие данные',
				grid: 2,
				inputs: basic
			},
			events: {
				title: 'События',
				hideNew: true,
				count: computed(() => groups.events.collapses.length),
				collapses: fishes
			},
			workers: {
				title: 'Работники',
				grid: 2,
				count: computed(() => Object.keys(groups.workers.cards).length),
				icon: 'plus',
				onClick: () => {
					modal.value = true
				},
				iconCard: 'user',
				button: 'Удалить',
				buttonClick: deleteWorker,
				cards: workers
			}
		})

		onMounted(async () => {
			if (!isNew) {
				const { get, data, error } = useApi(`/factory/${idDetail}`)

				await get()

				if (error.value) return notFoundDetail()

				const {
					name,
					phone,
					address,
					listOfWorkers
				} = data.value

				const listOfActions = await getAction()

				setBasic({ name, phone, address })

				setFishes(listOfActions)

				listOfWorkers.forEach(work => {
					const { id, workerFIO: title, postName: status } = work
					setWorker({ id, title, status })
				})
			} else {
				setBasic({ name: '', phone: '', address: '' })
			}
		})

		const modal = ref(false)

		return {
			groups,

			modal,

			createWorker,

			removeFactory,
			saveFactory
		}
	}
}
</script>
